<template>
  <div class="row ">
    <div class="col-12">
    <div class="title-nav">
      <h2>{{ $t("Register_order") }}</h2>
      <div class="d-flex align-items-center">
        <Show_Price
          v-if="OrderData?.status?.id == 5 || (OrderData?.status?.id == 4 && OrderData?.price_offers?.length > 0 && OrderData?.price_offers[0]?.status !== 'expired')"
          :price_offers="OrderData?.price_offers"
        />
      </div>
      </div>
    </div>
    <div class="register-order-list">
      <div class="item" v-for="item in Logs" :key="item.id">
        <div class="d-flex align-items-center">
          <div class="title">#{{ $t(`${item.id}`) }} -</div>
          <div class="title">{{ $t(`${item.action}`)}}</div>
        </div>
        <div class="description-date">
          <div class="description">{{ item.contextMessage[this.$i18n.locale] }}</div>
          <div class="date">{{ item.created_at }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  props: {
    Logs:Array
  },
};
</script>
